<template>
	<div>
		<div class="container">
			<div class="areaData darkBack" v-for="(item,index) in dataList" :key="index">
				<div class="areaDataItem">
					<div class="lightBlueFont">{{item.area}}</div>
					<div class="font31 blueFont" style="margin-top:0.2rem;">
						<countTo :startVal='0' :endVal='item.num' :duration='3000'></countTo>
					</div>
				</div>
				<div class="areaDataItem">
					<div class="lightBlueFont">{{item.resumption}}</div>
					<div class="font31 greenFont" style="margin-top:0.2rem;">
						<countTo :startVal='0' :endVal='item.percentage'  :decimals="2" :duration='3000'></countTo>%
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {getAjax, timestamp} from "/src/assets/js/websocket";

	// 引入数字滚动组件
import countTo from 'vue-count-to';
  export default {
    components: { countTo },
		data() {
			return{
				dataList:[
					// {
					// 	area:"越城区社会体育指导员人数",
					// 	num:0,
					// 	resumption: "履职率",
					// 	percentage:0
					// },
					// {
					// 	area:"诸暨市社会体育指导员人数",
					// 	num:0,
					// 	resumption: "履职率",
					// 	percentage:0
					// },
					// {
					// 	area:"新昌县社会体育指导员人数",
					// 	num:0,
					// 	resumption: "履职率",
					// 	percentage:0
					// },
					// {
					// 	area:"柯桥区社会体育指导员人数",
					// 	num:0,
					// 	resumption: "履职率",
					// 	percentage:0
					// },
					// {
					// 	area:"上虞区社会体育指导员人数",
					// 	num:0,
					// 	resumption: "履职率",
					// 	percentage:0
					// },
					// {
					// 	area:"嵊州市社会体育指导员人数",
					// 	num:0,
					// 	resumption: "履职率",
					// 	percentage:0
					// },
				],
			}
		},
		methods:{
			// 获取指导员数据
			getInstructor(){
				let that = this;
				var data = {
					token: window.getToken(),
					app_id: window.appId,
					time: timestamp(),
				}
				getAjax({
					url: "/map/screen/getHomeSocialSportsInstructor",
					method:"GET",
					data:data,
					success:function(res){
						// console.log(res)
						that.dataList = [];
						res.data.area_list.forEach((item,index)=>{
							// if(item.name == "越城区"){
							// 	that.dataList[0].num = item.resumption_num;
							// 	that.dataList[0].percentage = item.resumption_lv;
							// }else if(item.name == "诸暨市"){
							// 	that.dataList[1].num = item.resumption_num;
							// 	that.dataList[1].percentage = item.resumption_lv;
							// }else if(item.name == "新昌县"){
							// 	that.dataList[2].num = item.resumption_num;
							// 	that.dataList[2].percentage = item.resumption_lv;
							// }else if(item.name == "柯桥区"){
							// 	that.dataList[3].num = item.resumption_num;
							// 	that.dataList[3].percentage = item.resumption_lv;
							// }else if(item.name == "上虞区"){
							// 	that.dataList[4].num = item.resumption_num;
							// 	that.dataList[4].percentage = item.resumption_lv;
							// }else if(item.name == "嵊州市"){
							// 	that.dataList[5].num = item.resumption_num;
							// 	that.dataList[5].percentage = item.resumption_lv;
							// }
							that.dataList.push({
								area:item.name ,
								num:item.resumption_num,
								resumption: "履职率",
								percentage:item.resumption_lv
							})
							// console.log(that.dataList)
						})
					},
				})
			}
		},
		mounted() {
			this.getInstructor();
		}
	}
</script>

<style scoped>
	.font31{font-size: 0.31rem}
	.greenFont{color: #5ED586;}
	.container{
		width: 5.89rem;
		height: 7rem;
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
	.areaData{
		width: 5.89rem;
		height: 1.23rem;
		display: flex;
		justify-content: space-around;
	}
	.areaDataItem{
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
</style>